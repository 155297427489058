import Experiments from '@wix/wix-experiments';

import { Spec, SPECS_SCOPE } from '../specs';

export const fetchExperiments = async (): Promise<Experiments> => {
  const experiments = new Experiments({
    scope: SPECS_SCOPE,
  });
  await experiments.ready();
  return experiments;
};

export const experimentsNames = {
  shorterSearchTermEnabled: Spec.ShorterSearchTerm,
};

export type ExperimentNames = typeof experimentsNames;

export type FeatureToggles = { [k in keyof ExperimentNames]: boolean };

export const getFeatureToggles = (experiments: Experiments): FeatureToggles => {
  return {
    shorterSearchTermEnabled: experiments.enabled(Spec.ShorterSearchTerm),
  };
};

export const loadAndParseFeatureToggles = async (
  errorLogger,
): Promise<FeatureToggles> => {
  try {
    return getFeatureToggles(await fetchExperiments());
  } catch (ex) {
    errorLogger(ex);
    return { shorterSearchTermEnabled: false };
  }
};
